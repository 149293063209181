/* AboutUs.css */

.about-us {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  width: 80vw;
  margin: 0 auto;
  background-color: azure !important;
  box-shadow:none !important;
   .about-page{
      min-width:80vw;
      max-width:80vw;
     text-align: left !important;
      line-height: 5vh;
      padding-right: 200px !important;
      color:#333;
      font-size: 16px !important;
      background-color: azure !important;

    }
    h2 {
      font-size: 34px !important;
      margin-bottom: 20px;
      color: #000203d9;
      font-weight: bold;
      text-transform: uppercase;
      text-align: left;
  
    }
@media(max-width:992px){
  min-width: 90vw !important;
  max-width:90vw !important;
  margin-left: -0.5rem;
 
  .about-page{
    padding-right: 0px !important;
    font-size: 20px !important;
    line-height: 4vh !important;

  }
}
@media(max-width:767px){
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: -3rem !important;
  .about-page{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    margin-bottom: 15px;
    padding-right: 1rem;
    min-width: 90vw;
    max-width:90vw !important;
    text-align: left !important;
    align-self: flex-start;
    padding-right: 0  !important;
    background-color: azure !important;

  
  }
button{
  min-width: 150px !important;
  min-height:50px !important;
  padding: 1rem 3rem !important;
  font-size: 1.2rem !important;
}

}
    
    
      .divider{
        position: relative;
        top: 2rem !important;
          }
          .donate{
          position: relative;
          display: flex;
          margin: 0 auto;
   
          }
          .donate-btn
          {
              background-color: #050505;
              color: rgb(255, 255, 255);
              box-shadow: rgba(251, 169, 5, 0.88) 0px -3px 2px 0px inset, rgba(235, 165, 2, 0.984) 0px -1px 3px 0px inset, rgba(221, 145, 4, 0.914) 0px -1px 2px 0px inset, rgba(0, 0, 0, 0.968) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(242, 150, 3, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(6, 5, 0, 0.946) 0px 3px 16px;
              text-shadow: 1px -2px 2px rgb(0, 0, 0, 0.5);
              border: none;
              padding: 10px 20px;
              font-size: 18px;
              cursor: pointer;
              transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
            
             &:hover {
              background-color: #e8a20a;
              transform:scale(1.1);color:#000;
            }
          }
    }
  
  

 
  