

  @import url('https://fonts.googleapis.com/css2?family=Alumni+Sans+Inline+One&family=Anton&family=Ibarra+Real+Nova&family=Josefin+Sans:wght@300&family=Lily+Script+One&family=Montserrat:wght@300;400&family=Nunito+Sans:opsz,wght@6..12,300&family=Parisienne&family=Poppins:wght@200&family=Rubik+Mono+One&family=Sacramento&display=swap');

  /*
  font-family: 'Alumni Sans Inline One', cursive;
font-family: 'Anton', sans-serif;
font-family: 'Ibarra Real Nova', serif;
font-family: 'Josefin Sans', sans-serif;
font-family: 'Lily Script One', cursive;
font-family: 'Montserrat', sans-serif;
font-family: 'Nunito Sans', sans-serif;
font-family: 'Parisienne', cursive;
font-family: 'Poppins', sans-serif;
font-family: 'Rubik Mono One', sans-serif;
font-family: 'Sacramento', cursive;
*/



html body {
  padding:0;
  margin: 0;
  overflow-x: hidden;
  min-height:100vh;
  height:auto;
height:auto;
min-width:100vw;
max-width: 100vw;
box-sizing: border-box;
position:relative;
color:aliceblue;
color:#333 !important;
font-weight: 400 !important;
background-color: azure !important;
}

.App {
min-height:100vh;
height:auto;
min-width:100vw;
box-sizing: border-box;
margin:0;
padding:0;
overflow-x: hidden;
}


.App-header {
  min-height: 100%;
  max-height:100%;
  min-width:100%;
  max-width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  overflow-x: hidden;
  background-color: azure;

}


