
.home{
  margin:0;
  padding:0;
  box-sizing: border-box;
  min-height:100vh;
  height:auto;
  min-width:100vw;
  max-width: 100vw;;
  position:relative;
  overflow-x: hidden;
  background:rgb(251, 252, 251);
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .head{
    background: transparent;
    min-height:100%;
    height:auto;
    border-bottom-left-radius: 10% !important;
    border-bottom-right-radius: 10% !important;
   
    &::after{
      content: "";
      width:100%;
      height:100%;
      position:absolute;
      background:rgb(244, 242, 242);
      opacity:0.2;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index: -2;
      border-bottom-left-radius: 10% !important;
      border-bottom-right-radius: 10% !important;
    }
  }
  @media(max-width:992px){
    justify-content: flex-start !important;
    align-items: flex-start !important;
    text-align: left !important;
  }
} 

/*............NAVBAR SECTION STYLED ........................................*/

.nav-bar{
/*   background: url(../../../public/image/veg-1.webp) center/cover no-repeat;
 */ 
 background:url(../../../public/image/dersert-1.webp)center/cover no-repeat !important;

  &::after{
    content: "";
    width:100%;
    height:100%;
    position:absolute;
    background:black;
    opacity:0.89;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: -2;
   border-bottom: 1px solid #bc8803;
}
 
  @media(max-width:767px){
    z-index: 100;
    padding-bottom: 2rem !important;

  }
}

 .logo{
    position:relative;
 
    @media(max-width:767px){
      margin-left: 0.5rem !important;
    }
  }

 
  .Links{
    z-index: 200;
    position: relative;
    border-bottom: 1px solid  #03252f22;;
    top:1rem;
    left: 1rem;
    display: flex;
    justify-content:space-around ;
    gap:5 !important;
    align-items: center;
   
  }
  .link1{
    min-width:100px;
    color: #f3f6f6 !important ;
    width:auto;
    z-index: 1000;
    transform: translateX(10%);
    font-size: 1.3rem !important;
     &:hover{
      cursor:pointer !important;
     }
      &:active {
        color: rgb(255, 115, 0) !important;
      } 
     
     @media(max-width:992px){
      flex: 0 !important;
      justify-self: left;
      align-self: flex-start;
      font-weight: bold;
      font-size: 1.3rem !important;
      transform: translateX(0%);
      min-width: 100%;
      border-bottom: 1px solid #ccc;
      padding:1rem 0;

    
     }
    }

     .link2,
     .link3,
     .link4,
     .link5
    {
      min-width:120px;
      text-decoration: none !important;
      width:auto;
      z-index: 1000;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
 -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
 transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  color: #fcfeff !important ;
  font-size: 1.3rem !important;
 &:hover{
  background: #03252f !important;
  border-bottom: 1px solid rgba(206, 98, 4, 0.996) !important;
  }

  @media(max-width:992px){
    flex: 0 !important;
    justify-self: flex-start;
    align-self: flex-start;
    text-align: left;
    font-weight: bold;
    font-size: 1.3rem !important;
    min-height:40px !important;
    min-width: 100%;
    border-bottom: 1px solid #ccc;
    padding:1rem 0;
 
   }
  }


.btn-collapse{
background:#f4efe4;
box-sizing: border-box;
margin-left: -1rem !important;
color: #bc8803 !important;
&:focus{
  box-shadow: none!important;
  outline:none;
}
  
}

@media(max-width:992px){
  .social-links1{
   font-weight: 600;
   margin-top: 1rem;
   font-size: xx-large;
   .s-link1{
    color:rgb(221, 182, 9) !important;
   font-size: 1.2rem;
     margin-left: 1rem;
   }
  
  } 
  .social-links2{
  margin-top: 1rem  !important;
   font-weight: 600;
   padding-bottom: 2rem !important;
   .s-link2{
    color:rgb(221, 182, 9) !important;
    margin-left: -2rem;
    font-size: 1.2rem;


   }
  } 
}

.nav-donation{
  background: url(../../../public/image/veg-1.webp) center/cover no-repeat;
  min-width: 100vw;
 
  .navbar-brand{
    min-width:60%
  }
  .link-donation{
    min-width:30%;
    box-shadow: rgba(251, 169, 5, 0.88) 
    0px -2px 2px 0px inset, 0px 4px 2px, rgba(242, 150, 3, 0.09)
     0px 0px 2px, rgba(0, 0, 0, 0.09) 
    0px 6px 2px, rgba(7, 6, 6, 0.946)
     0px 3px 6px;
    background: #000;
    .linker{
    color:#f3f6f6 !important;

    }

    @media(max-width:992px){
      display: flex;
      .navbar-brand{
        background: red !important;
        max-width:20% !important;
        min-width:20% !important;
        
      }
    }
  }
  &::after{
    content: "";

    width:100%;
    height:100%;
    position:absolute;
    background:black;
    opacity:0.89;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: -2;
  }
}
.linkers{
  &:hover{
    background: #03252f !important;
    border-bottom: 1px solid rgba(206, 98, 4, 0.996) !important;
    }
    @media(max-width:992px){
      
      bottom:0;
      transform: translatex(-18rem);
    }
}
/*............HERO SECTION STYLED ........................................*/

