/* Projects.css */

.projects {
margin-left: auto;
min-height:100vh;
height:auto;
width:80%;
padding-left:0;
position: relative;
top:14rem;
font-family: Verdana, Geneva, Tahoma, sans-serif;
h5{
  text-align: left;
  font-size: 24px !important;
}
  @media(max-width: 992px){
 min-width:90vw;

  }
    h3{
      padding: 2rem 5px !important;
      min-height: 60px;
      background: url(../../../public/image/veg-1.webp) center/cover no-repeat;
      box-shadow: rgba(251, 169, 5, 0.88) 0px -3px 2px 0px inset, rgba(235, 165, 2, 0.984) 0px -3px 3px 0px inset, rgba(221, 145, 4, 0.914) 0px -9px 4px 0px inset, rgba(222, 174, 1, 0.897) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(242, 150, 3, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(6, 5, 0, 0.946) 0px 3px 16px;
      &::after{
        content: "";
        width:100%;
        height:100%;
        position:absolute;
        background:black;
        opacity:0.89;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: -2;
  
    }
      
      display: flex;
      justify-items: center;
      align-self: center;
        text-transform: capitalize;
       text-align: center;
        margin-bottom: 2rem;
       min-width: 100%;
        @media(max-width: 992px){
          font-size: 2rem !important;
         padding-top: 0 !important;
         padding-bottom: 1rem !important;
    
        }
        @media(max-width: 500px){
        font-size: 2rem !important;
         padding-top: 0 !important;
         padding-bottom: 1rem !important;
         text-align: center !important;
    
        }
 
    }


    .sub-tittle{
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 20px;
    }
    .project-list {
      list-style: none;
      padding: 0;
      margin-top: 2rem;
      background-color: azure !important;

      .page{
        background-color: azure !important;
        padding-right: 4rem;
        @media(max-width:767px){
          padding-right: 0 !important;
        }
      }
    }
    
    .project-list li {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      background-color: azure !important;

    }
    
    .project-info {
      background-color: azure !important;
      h5{
      font-size: 20px;
      margin-bottom: 10px;
      color: #000000d9;
      font-weight: bold;
      @media(max-width: 992px) {
        min-width:90vw !important;
        font-size: 24px;
        text-align: left !important;
      }
      @media(max-width: 500px) {
        min-width:90vw !important;
        font-weight: 900;
      }
    }
  }
    .project-info h6 {
      font-size: 16px;
      margin-bottom: 15px;
      line-height: 5vh;
      color:#333 !important;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      background-color: azure !important;

      @media(max-width:992px){
      text-align: left !important;
      min-width: 90vw;
      line-height: 4vh;
      font-size: 20px;

      }

    }
    
    .project-image img {
      max-width: 100%;
      height: auto;
      border-radius: 5px;
      box-shadow: rgba(251, 169, 5, 0.88) 0px -3px 2px 0px inset, rgba(235, 165, 2, 0.984) 0px -3px 3px 0px inset, rgba(221, 145, 4, 0.914) 0px -9px 4px 0px inset, rgba(13, 10, 0, 0.897) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(242, 150, 3, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(6, 5, 0, 0.946) 0px 3px 16px;
      
    }
    
    @media screen and (min-width: 992px) {
      .project-list li {
        flex-direction: row;
      }
    
      .project-info {
        flex: 1;
        margin-right: 20px;
       
      }
    
      .project-image {
        flex: 1;
        
      }
    
      .project-image img {
        max-width: 100%;
        height: auto;
        border-radius: 5px;
      }
     
    }

    
    
  }
  
 