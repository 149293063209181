.contact-inner{
    position: relative;
    background:url(../../../public/image/overlayBG1.webp)center/cover no-repeat;
    min-width: 80%;
    padding-top: 2rem;
    max-width: 80%;
    position:relative;
    min-height:65vh;
    height: auto;
    margin:0 auto;
    top:100%;
    bottom:0;
    left:0;
    right:0;
    padding: 0.5rem 2rem 2rem 2rem;
    header{
        h4{
            text-align:center !important;
            min-width: 100%;
            padding: 0;
            font-size: 3rem;
            padding: 1rem 0;
            font-weight: 800;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            @media(max-width:650px){
                font-size: 2.5rem !important;

            }
        
        }
    }
    
    &::after{
        content: "";
        width:100%;
        height:100%;
        position:absolute;
        background:black;
        opacity:0.89;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: -2;
    }
    
    
        @media(max-width:992px){
            min-width: 90vw !important;
            margin-left:-2rem !important;
            p{padding:0 !important}
        
    }


    box-shadow: rgba(3, 3, 3, 0.914) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 2px, rgba(0, 0, 0, 0.07) 0px 8px 6px, rgba(0, 0, 0, 0.07) 0px 16px 12px, rgba(249, 241, 241, 0.963) 0px 2px 4px;    
    label{
        color: #f4f1ec !important
    }
    input,textArea{
        box-shadow: 1px -1px 2px grey;
        font-size: 14px;
        font-weight: 600;
    }
    button{
        min-width:200px;
        min-height:60px;
        max-width:200px;
        max-height:60px;
        color:#030200 !important;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
        font-size: 1.2rem;
        font-weight: 700;
        background:#fffffe;
        border:none;
        outline:none;
    
    }
   form {
    background: transparent !important;

   }
}