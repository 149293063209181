.AboutKevin{
min-height: 100vh;
height:auto;
padding: 2px 8rem 2px 0px;
position: relative;
width:80%;
position: relative;
top:10rem;
p{
  padding-right: 2rem !important;
  font-size: 16px !important;
  line-height: 5vh;
  font-family: Verdana, Geneva, Tahoma, sans-serif;    
  color: #333;
 

}
img{
  box-shadow: rgba(251, 169, 5, 0.88) 0px -3px 2px 0px inset, rgba(235, 165, 2, 0.984) 0px -3px 3px 0px inset, rgba(221, 145, 4, 0.914) 0px -9px 4px 0px inset, rgba(13, 10, 0, 0.897) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(242, 150, 3, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(6, 5, 0, 0.946) 0px 3px 16px;
}
@media(max-width:992px){
  background-color: azure !important;
    min-width: 95vw;
    top:8rem;
    margin:0 auto;
    padding: 0 !important;
   p{
    font-size: 20px !important;
    line-height: 4vh;
    text-align: left;

   }
}
@media(max-width:767px){
  padding: 2px 0.5rem 2px;
  top:12rem;
}
@media(max-width:580px){
  padding: 2px 8rem 2px;
}
.text-box{
  @media(max-width:767px){
    padding: 0px 0.5rem 0px;
  }

}
.title{
  position: relative;
  background: url(../../../public/image/veg-1.webp) center/cover no-repeat;
  box-shadow: rgba(251, 169, 5, 0.88) 0px -3px 2px 0px inset, rgba(235, 165, 2, 0.984) 0px -1px 3px 0px inset, rgba(221, 145, 4, 0.914) 0px -1px 2px 0px inset, rgba(0, 0, 0, 0.968) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(242, 150, 3, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(6, 5, 0, 0.946) 0px 3px 16px;
   &::after{
    content: "";
    width:100%;
    height:100%;
    position:absolute;
    background:black;
    opacity:0.89;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index: -2;

}
  @media(max-width:992px){
    padding: 2px 0 2px;
    font-size: xx-large !important;
    min-width:100vw !important;
    p{
      line-height: 4.5;
      text-align: center;
      font-size: 16px !important;

    }
  }
  @media(max-width:550px){
    padding: 2px 0 2px;
    font-size: 1.7rem !important;
    min-width:100vw !important;
    max-width:100vw !important;
  }
}

}