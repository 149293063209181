


/* section{
min-height:100vh;
min-width: 100vw;
background: #050202;
margin-top: -0.1rem !important;
padding-top: 4rem !important;
@media(max-width:992px){
  background: #f7f7f7 !important;
} */

.donation-container {
    max-width: 800px;
    min-width: 800px;
    min-height: 400px !important;
    height: auto !important;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background:url(../../../public/image/overlayBG1.webp)center/cover no-repeat;
    position:relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden !important;
  }
  
  h2 {
    text-align: center;
    color:#bc8803;
  }
  
  label {
    font-weight: bold;
    color:#bc8803;
    }

  .dropdown-toggle {
    font-weight: 300;
    height: auto;
    z-index: 1;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #bc8803;
    border-radius: 5px;
    outline: none;
    background: #333;
    color: aliceblue;
   
  }
  .dropdown{

  ul{ 
    background: #050202 !important;
  li{
    border: 1px solid #bc8803;
    border-radius: 5px;
    padding: 5px;
    background: transparent !important;
    .dropdown-item{
    &:hover{
      background: #fbf8f8 !important;
      color:black !important;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    }

    }


  }
  }
  }


  
  .bank-details {
    border: 1px solid #bc8803;;
    padding: 10px;
    border-radius: 5px;
    background: #f7f7f7;
    z-index: 1000;
    @media(max-width:767px){
      text-align: center;
    }
    .credit-btn{
      background:url(../../../public/image/overlayBG1.webp)center/cover no-repeat;
      border: 1px solid #bc8803;
      color: beige !important;
      border-radius: 5px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      padding: 8px 15px;
      font-size: 1.1rem !important;


    }
  }
  
  .bank-details h3 {
    margin-top: 0;
    color:#050202
  }
  .cards{
    z-index: 10;
    .pay-pal{
      position:absolute;
      width:80%;
      height:80%;
      top: 50%;
      left: 50%;
      z-index: 100;
      padding-top: 2rem !important;
      background: #f7f7f7;
      transform: translate(-50%, -50%);
  
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
      transition: cubic-bezier(0.215, 0.610, 0.355, 1);

    }
  }
  .pal-pal-button{
    min-width:45vw !important;
    max-width:45vw !important;
    .pay-pal-inner{
      min-width:100% !important;
      max-width:100% !important;
    }
    @media(max-width:992px){
      min-width:70vw !important;
      max-width:70vw !important;
    }
  }

  
  /* Add responsive styles as needed */
  @media (max-width: 768px) {
    .pay-pal{
      min-width:90vw !important;
      h2{
        align-self: center;
        margin-left:-5rem;
      }
    }
    .pay-pal-inner{
      min-width:80vw !important;
    }
    .donation-container {
      width: 100%;
    }
  }
