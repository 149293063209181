/* Donate Page Styles */

.donate-page {
    text-align: center;
    padding: 20px 20px 20px 0px;
    width:80% !important;
    margin: 0 auto;
    height:100vh;
    height:auto;
    margin-top:16rem;
    .support-text{
      font-size: 18px;
      margin-bottom: 30px;
      line-height: 5vh !important;
      padding-top: 1rem;
      text-align: left;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      background-color: azure !important;
    
    } 
    @media(max-width:992px){
      min-width: 90vw;
      .support-text{
        line-height: 4vh !important;
        font-size: 20px;
        text-align: left !important;

      }
    }
  }
  
  .donate-page h2 {
    font-size: 34px;
    color:#ffffff !important;
    margin-bottom: 10px;
    font-weight: 900;
    box-shadow: rgba(251, 169, 5, 0.88) 0px -3px 2px 0px inset, rgba(235, 165, 2, 0.984) 0px -1px 3px 0px inset, rgba(221, 145, 4, 0.914) 0px -1px 2px 0px inset, rgba(0, 0, 0, 0.968) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(242, 150, 3, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(6, 5, 0, 0.946) 0px 3px 16px;
    @media(max-width:992px){
      font-size: 2rem;
      text-align: center !important;
      padding: 1rem !important;
      min-width: 90vw !important;


    }
    @media(max-width:500px){
      font-size: 1.8rem;
      text-align: center !important;
      padding: 1rem !important;

    }
  
  
  }
  
  .sub-h2{
    text-align: left;
    position:relative;
    left:12%;
  }
 
  
  .donate-button
  {
      background-color: #000000;
      color: rgb(255, 255, 255);
      text-shadow: 1px -2px 2px rgb(0, 0, 0, 0.5);
      box-shadow: rgba(251, 169, 5, 0.88) 0px -3px 2px 0px inset, rgba(235, 165, 2, 0.984) 0px -1px 3px 0px inset, rgba(221, 145, 4, 0.914) 0px -1px 2px 0px inset, rgba(0, 0, 0, 0.968) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(242, 150, 3, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(6, 5, 0, 0.946) 0px 3px 16px;
      border: none;
      padding: 10px 20px;
      font-size: 18px;
      cursor: pointer;
      transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
     @media(max-width:992px){
        min-width: 150px !important;
        min-height:50px !important;
        padding: 1rem 3rem !important;
        font-size: 1.2rem !important;

     }
     &:hover {
      background-color: #e9bc09;
      transform:scale(1.1);
      color:#000;
    }
  }
  @media(max-width:992px){
   .form-btn{
    justify-content: center;
    align-items: center;
   }
  }