.footer-cover{
    background:url(../../../public/image/overlayBG1.webp)center/cover no-repeat;
    min-width: 100vw;
    padding-top: 2rem;
    max-width: 100vw;
    position:relative;
    min-height:65vh;
    margin-top: 5rem;
    top:100%;
    bottom:0;
    left:0;
    right:0;
    margin-left: 0;
    h5{
        color:#e59e03;
    }
    
    &::after{
        content: "";
        width:100%;
        height:100%;
        position:absolute;
        background:black;
        opacity:0.4;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: -2;
    }
    .footer-wrapper{
        color: #ffffff !important;
       
    }
}
.footer-bottom{
    color:#ccc !important; 
    font-size:1rem !important ;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    border-top: 1px solid #ccc;
    
}
.footer-logo{
    @media(max-width:650px){
        max-width:80vw !important;
        margin:0 auto;
        padding: 5px 2rem !important
    }
}

