
.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    background:url(../../../public/image/dersert-1.webp)center/cover no-repeat !important;

/*     background: rgba(21, 0, 12, 0.737);
 */    overflow: hidden;
    margin-top:6rem;
    padding-bottom: 3rem;
    min-height:80vh;
    min-width:90vw;
  @media(max-width:992px){

  }
    @media(max-width:399px){
      min-height:85vh 

    }
    .thanks-message{
     visibility: hidden;
     button{
      background: rgba(0, 0, 0, 0.955) !important;
      margin: 7rem;
      margin-left:0.2rem;
      font-family: Verdana, Geneva, Tahoma, sans-serif;
      border: 2px solid #bc8803 !important;
      min-height:50px !important;
      min-width:150px !important;
      outline:none;
      box-shadow: none;
      :hover{
      background:transparent !important;
      color:#eba206 !important;
      transition: cubic-bezier(0.215, 0.610, 0.355, 1);
      }
      @media(max-width:767px){
        margin-top: 9rem !important
      }
      @media(max-width:450px){
      display:none !important;
      }

     }
     @media(max-width:399px){
      margin-top: 15rem !important;
      padding-bottom: 2rem !important;
      min-width:100vw !important;
       .span3{
        font-size:1.2rem !important;
        
      }
    }
    }
   
    &::before{
      content: "";
      width:100%;
      height:100%;
      position:absolute;
      background:black;
      opacity:0.7;
      top:0;
      left:0;
      right:0;
      bottom:0;
      z-index: -2;
      @media(max-width:992px){
        opacity:0.7;
      }

  }
      @media(max-width:1200px){
        margin-top: -3rem !important;
        padding-top: 150px;
        min-width:100%;
        max-width:100% !important;
      }
      @media(max-width:1010px){
        padding-top: 180px;
        min-width:100%;
        max-width:100% !important;
      
      }
      @media(max-width:992px){
        margin-top: 5rem !important;
        padding-top: 150px;
        min-width:100%;  
    
        .thanks-message{
          visibility: visible !important;
          margin: auto;
          position: absolute;
          bottom:0%;
          left:2%;
          top:calc(100vh - 50vh);
          transform: translateY(70%);
          padding: 0rem 1rem 3rem;
          z-index: 2000;
          color:#bc8803;
          font-family: 'Dancing Script', cursive;
          font-size:xx-large;
           .span2{
            font-family:garamond;
            line-height: 5.5vh !important;
            font-size: 2rem;
            @media(max-width:500px){
              font-size:1.5rem !important;
       
            }
           }
          i{
            font-size:xx-large;
          }
        
        }
       
      }
      @media(max-width:767px){
        padding-bottom: 6rem !important;
        margin-top: 8rem !important;
        .thanks-message{
          top: 10rem !important;
          min-width: 90vw;
          padding-left: 2rem !important;
  
          }
      }
      
      @media(max-width:650px){
        .thanks-message{
          top: 6rem !important;
  
          }
      }
      
      @media(max-width:450px){
        .thanks-message{
        top: 1rem !important;
        }
      }

      @media(max-width:399px){
        .thanks-message{
          padding-left: 0.5rem !important;
          top: -6rem !important;
          
          }
      }
  
   
  }

    .hero-content {
        display: grid;
        background:url(../../../public/image/dersert-1.webp)center/cover no-repeat !important;
        grid-template-columns: repeat(2, auto);
        justify-content: center;
        align-items: center;
        gap: 5px;
        position: relative;
        top:-5rem;
        bottom:0;
        z-index: 1;
        align-self: center;
        justify-self: center;
        overflow: hidden;
        min-width:100vw;
        min-height:100%;
        .text-page-1{
          width:35vw;
           padding-left: 2rem;
           @media(max-width:992px){
            min-width:90vw !important;
            margin: 0 auto;
            padding-left: 0.1rem;
            padding-top: 1rem;
            p{
              padding-right: 3rem;
            }
           
         
           }
      
        }
        &::after{
          content: "";
          width:100%;
          height:100%;
          position:absolute;
          background:black;
          opacity:0.6;
          top:0;
          left:0;
          right:0;
          bottom:0;
          z-index: -2;
          @media(max-width:992px){
            opacity:0.5;
          }
    
        }
       
        @media(max-width: 992px){
         grid-template-columns: repeat(1, auto);
          overflow-x: hidden !important;
          height:100%;
          left:0 !important;
          right:0;
          bottom:0;
          top:-10rem !important;
          min-width: 100vw !important;
          padding-top:2rem !important;
          height:auto;
         
       
        }
      }
      
      h2 {
        font-size: 30px;
        font-weight:bold;
        margin-bottom: 20px;
        text-transform: capitalize;
        color: #eef1f2d9;
        @media(max-width: 992px){
          font-size: 28px !important;
          padding-right: 3rem !important;

        }
       @media(max-width:767px){
        min-width:100vw !important;
        font-size: 28px !important;
       
       }
       @media(max-width:430px){
        font-size: 25px !important;

           }
      }
      
      .tex  {
        position: relative;
        font-size: 18px;
        margin-bottom: 40px;
        color:#bc8803;
        transform: translateY(2rem) !important;
        z-index: 2000;
         box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px !important;
         
        @media(max-width:767px){
          max-width: 95vw !important;
          padding-right: 1rem !important;
        }
        
      }
      
      .hero-image {
       min-width:50vw !important;
        position: relative;
        z-index: 100;
        padding: 2rem !important;
       
        
       
        @media(max-width: 992px){
          min-width:50vw !important;
          border-bottom-left-radius: 0;
          position: relative;
          padding:0rem !important;
        }
        @media(max-width: 767px){
          min-width:50vw !important;
          border-bottom-left-radius: 0;
          margin:0 !important;




        }
    
      }
      
      .hero-image img {
        min-width: 100%;
        height:100%;
        border-radius: 10% 30% 50% 70%;
        position: relative;
        height: auto;
        filter: grayscale(80%);
        --s: 10px; /* control the size */
        padding: calc(3*var(--s));
        --_g: #eba206 1px,#f907e912 0 calc(100% - 1px),#e4ac03 0;
        --_s:  50%/calc(100% - 2*var(--s)) calc(100% - 2*var(--s));
        background: 
          linear-gradient(      var(--_g)) var(--_s) no-repeat,
          linear-gradient(90deg,var(--_g)) var(--_s) no-repeat,
          conic-gradient(from 90deg at 1px 1px,#080808f6 25%,#ddaa01 0)
          calc(2*var(--s)) calc(2*var(--s))/calc(100% - 4*var(--s)) calc(100% - 4*var(--s));
          box-shadow: rgba(3, 3, 3, 0.914) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 2px, rgba(0, 0, 0, 0.07) 0px 8px 6px, rgba(0, 0, 0, 0.07) 0px 16px 12px, rgba(245, 241, 249, 0.049) 0px 2px 4px;    

        @media(max-width: 992px){
       border-radius: 0 !important;
       filter: grayscale(20%);
       padding: calc(3*var(--s));
       --_g: #eba206 1px,#635f5f00 0 calc(100% - 1px),#e4ac03 0;
       --_s:  50%/calc(100% - 2*var(--s)) calc(100% - 2*var(--s)); 
        
      
        }
    
      }
  
    .curve {
      position: absolute;
      width:35vw;
      left: 1%;
      right: 0;
      bottom:-2.5rem;
      height: 100px;
      background-color: #bc8803;
       z-index: 1;
       padding-right: 5rem !important;
       @media(max-width: 992px){
      display: none;

        }
      
    }
    butto{
      z-index: 3000;
      top:93%;
      left:4%;
      border: 2px solid #bc8803 !important;
      display:none;
     
      @media(max-width:992px){
      display:block
      }
      @media(max-width:767px){
      left:6%;
      }
      }